<template>
<div>
    <v-system-bar height="50" app color="#fff" dark elevation="4" style="box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.13);" class="hide-on-md">
        <v-btn color="#745CA8" @click.stop="toggleSideBar" v-show="isOnCourse" v-if="shouldShowhamburger">
            <i class="fas fa-bars"></i>
        </v-btn>
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="false"></v-app-bar-nav-icon>
        <div>
            <a href="/">
                <v-img alt="portal Logo" class="shrink" contain src="../assets/images/cpm-logo-text-line.svg" width="140" />
                <!-- <object data="../assets/images/cpm-logo-text-line.svg" width="200"> </object> -->
            </a>
        </div>
        <v-spacer></v-spacer>
        <div style=" text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/','_blank')"><strong style="fontSize: 12px;">TLIC</strong></v-btn>
        </div>
        <div style="text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://docs.tlic.cmu.ac.th/course-portal-management/','_blank')"><strong style="fontSize: 12px;">Manual</strong></v-btn>
        </div>
        <div style="text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/contact-us','_blank')"><strong style="fontSize: 12px;">Contact us</strong></v-btn>
        </div>

        <!-- <div style="text-align: center;"  >
        <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://enroll.elearning.cmu.ac.th/mango','_blank')" ><strong style="fontSize: 12px;">Mango Sandbox</strong></v-btn>
      </div> -->
        <div style="max-width: fit-content; text-align: center;">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " @click="handleShowSchedule"><strong style="fontSize: 12px;">Important Dates</strong></v-btn>
        </div>

        <div style="max-width: fit-content; text-align: center; " v-if="ShowAdmin === 'true'">
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/admin-console'}"><strong style="fontSize: 12px;">Admin</strong></v-btn>
        </div>
        <div style="max-width: fit-content; text-align: center;" v-else>
            <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/'}"><strong style="fontSize: 12px;">{{ShowAdmin !== 'true' && userProfile.jobTitle?.toLowerCase().includes('employee')? "Teacher" : "Student"}}</strong></v-btn>
        </div>

        <div style="padding: 0.3rem;  height: 50px; width: 200px; margin-right: -8px; background:  linear-gradient(270deg, #765299 -6.19%, #5E50AD 103.53%);">
            <v-row class="d-flex">
                <v-col class="align-self-center" style="margin-left: 5px;">
                    <h6 v-if="String(userProfile?.name).length > 20">{{ userProfile.name.slice(0, 20)}}...</h6>
                    <h6 v-else>{{ userProfile.name}}</h6>
                </v-col>
                <v-col sm="2" md="2" lg="2" style="margin-right: 23px;">
                    <v-menu bottom rounded>

                        <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon white large>
                                    mdi-account-circle
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-card>
                            <v-list-item-content class="justify-center">
                                <div class="mx-auto text-center">
                                    <v-btn depressed rounded text to="/signout">
                                        Signout
                                    </v-btn>
                                </div>
                            </v-list-item-content>
                        </v-card>
                    </v-menu>
                </v-col>

            </v-row>

        </div>

    </v-system-bar>
    <v-system-bar height="60" app color="#ffffff" dark elevation="4" class="show-on-md">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="false"></v-app-bar-nav-icon>
        <v-row class="mt-1">
            <div>
                <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' } " :click="OnClickAdmin()" :to="{path:'/'}">
                    <v-img alt="portal Logo" class="shrink" contain src="../assets/images/cpm-logo-text-line-beta-2.svg" width="140" />
                    <!-- <object data="../assets/images/cpm-logo-text-line.svg" width="200"> </object> -->
                </v-btn>

            </div>
            <v-spacer></v-spacer>

            <div style="padding: 0.3rem;  height: 60px; width: 200px; margin-right: -8px; margin-top: -4px; background:  linear-gradient(270deg, #765299 -6.19%, #5E50AD 103.53%); text-align: center;">
                <v-row class="d-flex justify-content-center">
                    <v-col class="align-self-center mt-3">
                        <h6 v-if="String(userProfile?.name).length > 20">{{ userProfile.name.slice(0, 20)}}...</h6>
                        <h6 v-else>{{ userProfile.name}}</h6>
                    </v-col>
                    <v-col>
                        <v-menu bottom rounded class="mt">

                            <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                    <v-icon white large>
                                        mdi-account-circle
                                    </v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <div style="max-width: 50px;  text-align: center; text-decoration: none; margin-right: 1rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/','_blank')"><strong style="fontSize: 12px;">TLIC</strong></v-btn>
                                </div>
                                <div style="max-width: 50px; text-align: center;  margin-right: 2rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://docs.tlic.cmu.ac.th/course-portal-management/','_blank')"><strong style="fontSize: 12px;">Manual</strong></v-btn>
                                </div>
                                <div style="max-width: 60px; text-align: center;  margin-right: 3rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://www.info.tlic.cmu.ac.th/contact-us','_blank')"><strong style="fontSize: 12px;">Contact us</strong></v-btn>
                                </div>

                                <!-- <div style="max-width: 120px; text-align: center; margin-right: 2rem;"  >
                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " onclick="window.open('https://enroll.elearning.cmu.ac.th/mango','_blank')" ><strong style="fontSize: 12px;">Mango Sandbox</strong></v-btn>
                  </div> -->

                                <div style="max-width: 120px; text-align: center; margin-right: 2rem;">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " @click="handleShowSchedule"><strong style="fontSize: 12px;">Important Dates</strong></v-btn>
                                </div>

                                <div style="max-width: 50px; text-align: center;  margin-right: 2rem;" v-if="ShowAdmin === 'true'">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/admin-console'}"><strong style="fontSize: 12px;">Admin</strong></v-btn>
                                </div>

                                <div style="max-width: 80px; text-align: center;  margin-right: 2rem;" v-if="ShowAdmin != 'true' && userProfile.jobTitle?.toLowerCase().includes('employee')">
                                    <v-btn depressed :style="{backgroundColor: 'rgb(255, 255, 255)' , color : '#666666'} " :click="OnClickAdmin()" :to="{path:'/'}"><strong style="fontSize: 12px;">Teacher</strong></v-btn>
                                </div>
                                <v-list-item-content class="justify-center">
                                    <div class="mx-auto text-center">
                                        <v-btn depressed rounded text to="/signout">
                                            Signout
                                        </v-btn>
                                    </div>
                                </v-list-item-content>
                            </v-card>
                        </v-menu>
                    </v-col>
                </v-row>
            </div>
        </v-row>
        <!-- <a href="/" style="display:inline-block;">
        <v-img
            alt="portal Logo"
            class="shrink"
            contain
            src="../assets/images/portal-logo.png"
            width="230"
        />
      </a> -->
        <!-- <v-btn icon style="display:inline-block;padding-bottom: 80px;">
        <v-icon dark class="mr-0" color="primary">
          mdi-account-circle
        </v-icon>
      </v-btn> -->
    </v-system-bar>
    <ModalSchedule :showModal="showSchedule" :dataList="schedule" @onClose="handleShowSchedule" />
</div>
</template>

<script>
const config = require(`@/config`)
import CryptoJS from 'crypto-js'
import ModalSchedule from '../components/ModalNotification/ModalSchedule.vue'
import dataEventCpm from '../config/dataEventCpm.json'

export default {
    name: 'Theheader',
    components: {
        ModalSchedule
    },
    data() {
        return {
            userProfile: {},
            ShowAdmin: false,
            showSchedule: false,
            schedule: dataEventCpm,
        }
    },
    watch: {
        // RouterName(val){
        //   // this.syncStatus = !val
        //   console.log("toggle :>" , val);
        //   this.RouterName = this.$route.name;

        // },
    },
    mounted() {
        this.setUserProfile()
        this.OnClickAdmin()
    },
    created() {

    },
    computed: {
        isOnCourse: {
            get() {
                return this.$store.state.isOnCourse
            },
            set() {
                console.log("isOnCourse")
            }
        },
        shouldShowhamburger() {
            return this.$route.meta.hamburger !== false
        },
    },
    methods: {
        handleShowSchedule() {
            if (this.showSchedule) {
                this.showSchedule = false
            } else {
                this.showSchedule = true
            }
        },
        async OnClickAdmin() {
            // console.log("onclick")
            // console.log("this.$route.query.page:>" , this.$route.name)

            if (this.$route.name === "Admin console") {
                this.ShowAdmin = false;
            } else {
                this.ShowAdmin = localStorage.getItem("grantAdmin");
            }
            // console.log("ShowAdmin",this.ShowAdmin)
        },
        setUserProfile() {
            const bytes = CryptoJS.AES.decrypt(localStorage.getItem("profile"), config.aesKey.key)
            this.userProfile = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
            this.$store.commit('setUserData', this.userProfile)
            // console.log("userProfile>" , this.userProfile.jobTitle?.toLowerCase().includes('employee'))
            this.ShowAdmin = this.userProfile.jobTitle?.toLowerCase().includes('employee');
        },
        toggleSideBar() {
            this.$store.state.sideBarIsShow ? this.$store.commit('setSideBarHide') : this.$store.commit('setSideBarShow')
        }
    },
}
</script>
